<template>
  <div class="jp-common-layout-center jp-flex-main">
    <el-form size="small" :inline="true" class="query-form" ref="searchForm" :model="searchForm"
      @keyup.enter.native="refreshList()" @submit.native.prevent>
      <el-form-item prop="name">
        <el-input size="small" v-model="searchForm.name" placeholder="请输入文件名称" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="searchList()" size="small" icon="el-icon-search">查询</el-button>
      </el-form-item>
      <el-form-item style="float: right">
        <!-- <el-button icon="el-icon-document" size="small" plain @click="getLog">日志</el-button> -->
      </el-form-item>
      <el-form-item style="float: right">
        <el-dropdown class="m_l1">
          <el-button type="primary" size="small">
            智能操作<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="download()" :disabled="dataListSelections.length <= 0">下载</el-dropdown-item>
            <el-dropdown-item @click.native="edit('move')"
              :disabled="dataListSelections.length <= 0">移动</el-dropdown-item>
            <!--                        <el-dropdown-item @click.native="edit('edit')" :disabled="dataListSelections.length <= 0">修改</el-dropdown-item>-->
            <el-dropdown-item @click.native="del()" :disabled="dataListSelections.length <= 0">删除</el-dropdown-item>
            <el-dropdown-item @click.native="share()" :disabled="dataListSelections.length <= 0">分享</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-form-item>
    </el-form>
    <div class="bg-white top">
      <el-table :data="dataList" v-loading="loading" size="small" height="calc(100% - 50px)"
        @selection-change="selectionChangeHandle" class="table">
        <el-table-column type="selection" header-align="center" align="center" width="50">
        </el-table-column>
        <el-table-column prop="status" label="序号" width="50">
          <template slot-scope="scope">
            <span>{{ (pageNo - 1) * pageSize + (scope.$index + 1) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="fileName" label="文件名称" show-overflow-tooltip />
        <el-table-column prop="fileFormat" label="文件类型" width="80">
          <template slot-scope="scope">
            <el-tag type="success">{{ scope.row.fileFormat }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="createDate" sortable label="上传时间" show-overflow-tooltip />
        <el-table-column header-align="center" align="center" width="210" label="操作">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" plain @click="view(scope.row.id)">详情</el-button>
            <el-button size="mini" type="success" plain @click="download(scope.row)">下载</el-button>
            <el-button size="mini" type="danger" plain @click="del(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="sizeChangeHandle" @current-change="currentChangeHandle" :current-page="pageNo"
        :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :total="total" background
        layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
    <!-- 查看 -->
    <FileDetails ref="fileDetails" @refreshDataList="refreshList"></FileDetails>
    <!--    日志    -->
    <Log ref="reorganizeLog"></Log>
    <!--修改、移动-->
    <EditForm ref="editForm" @refreshDataList="refreshList"></EditForm>

    <user ref="user" @refreshDataList="refreshList"></user>
  </div>
</template>

<script>
import FileDetails from '../dataCollection/documents/fileDetails'
import Log from "../dataCollection/log"; // 上传日志
import EditForm from './editForm' // 修改
import user from './user' // 分享用户
export default {
  components: {
    FileDetails,
    Log,
    EditForm,
    user
  },
  data() {
    return {
      searchForm: {
        name: ""
      },
      dataList: [],
      pageNo: 1,
      pageSize: 10,
      total: 0,
      index: '1',
      dataListSelections: [],
      loading: false,
      log: {
        operModular: "6",//模块
        operType: "1",//类型
        operTerm: "",//内容
        operSystem: "1",//结果
      },
    }
  },
  mounted() {
    this.refreshList();
    // this.getFileList();
  },
  methods: {
    searchList() {
      this.pageNo = 1;
      this.pageSize = 10;
      this.total = 0;
      this.refreshList();
    },
    // 获取数据列表
    refreshList() {
      this.loading = true
      this.$axios(this.api.common.queryAllByLimitMyFile, {
        'pageNo': this.pageNo,
        'pageSize': this.pageSize,
        ...this.searchForm
      }, 'get').then(data => {
        if (data && data.status) {
          this.dataList = data.data.records
          this.total = parseInt(data.data.total)
          this.loading = false
        }
      })
    },
    //下载
    download(row) {
      let rows = [];
      if (row) {
        rows = [row]
      } else {
        rows = this.dataListSelections
      }
      // console.log(rows);
      let that = this;
      this.log.operType = '19'
      this.log.operSystem = '1'
      rows.forEach(function (v) {
        const a = document.createElement('a')
        //   let url = baseUrl + binding.value // 若是不完整的url则需要拼接baseURL
        const url = v.url;  // 完整的url则直接使用
        // 这里是将url转成blob地址，
        fetch(url).then(res => res.blob()).then(blob => { // 将链接地址字符内容转变成blob地址
          a.href = URL.createObjectURL(blob)
          // console.log(a.href)
          a.download = v.fileName + "." + v.fileFormat // 下载文件的名字
          // a.download = url.split('/')[url.split('/').length -1] //  // 下载文件的名字
          document.body.appendChild(a)
          a.click()
          that.log.operSystem = '0'
          that.log.operTerm = v.fileName
        })
      })
    },
    //移动，修改
    edit(method) {
      this.$refs.editForm.init(method, this.dataListSelections, 'reorganize')
    },
    //分享
    share() {
      this.$refs.user.init(this.dataListSelections)
    },
    // 删除
    del(row) {
      let ids = ""
      if (row) {
        ids = row.id
      } else {
        ids = this.dataListSelections.map(item => {
          return item.id
        }).join(',')
      }
      this.$confirm(`确定删除所选项吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.log.operType = 1
        this.$axios(this.api.common.delete, {
          'ids': ids,
          "delFlag": 1,
        }, 'put').then(data => {
          if (data.status) {
            this.log.operSystem = '0'
            this.$message.success("删除成功")
          } else {
            this.log.operSystem = '1'
          }
          this.logSave(this.dataListSelections, row)
        })
      })
    },
    //新增日志
    logSave(dataListSelections, row) {
      if (row) {
        this.log.operTerm = row.fileName
        // this.$axios(this.api.common.logSave, this.log, 'post').then(data => {
          this.refreshList()
        // })
      } else {
        let that = this;
        let batchList = []
        dataListSelections.forEach(function (v) {
          let log = JSON.parse(JSON.stringify(that.log))
          log.operTerm = v.fileName
          batchList.push(log)
        })
        // this.$axios(this.api.common.batchInsertLogin, batchList, 'post').then(data => {
          if (dataListSelections.length == that.dataList.length) {
            that.pageNo = 1
          }
          this.refreshList()
        // })
      }
    },
    // 查看
    view(id) {
      this.$refs.fileDetails.init(id, 'gather')
    },
    //日志
    getLog() {
      this.$refs.reorganizeLog.init('6')
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageNo = 1
      this.refreshList()
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageNo = val
      this.refreshList()
    },
  }
}
</script>

<style scoped></style>
